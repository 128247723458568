<template>
  <div class="overlay">
    <div :class="`popup ${width}`">
      <div
        v-if="$slots.title || !!onClose || $slots.titleButton"
        :class="{
          'flex items-center w-full justify-between': true,
          [padding]: true,
          'pb-0': !$slots.message,
        }"
      >
        <h2 class="s1 m lg:d text-center">
          <slot name="title" />
        </h2>
        <span
          v-if="onClose"
          class="icon-remove cursor-pointer text-lg"
          @click="onClose"
        />
        <slot name="titleButton" />
      </div>
      <h2
        v-if="$slots.message"
        :class="{
          'b2 text-center w-full': true,
          'border border-y-surface-60': showDividers,
          [padding]: true,
        }"
      >
        <slot name="message" />
      </h2>
      <div class="content">
        <div
          :class="{
            ['footer']: true,
            [padding]: true,
          }"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    padding: {
      type: String,
      default: "p-8",
    },
    width: {
      type: String,
      default: "w-[374px]",
    },
    showDividers: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: null,
    },
  },
};
</script>

<style scoped>
.overlay {
  @apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-40;
}

.popup {
  @apply bg-surface-10 rounded-xl w-[374px] z-50;
}

.content {
  @apply text-center;
}

.footer {
  @apply flex justify-center items-center flex-col gap-3;
}
</style>
